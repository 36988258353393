import Vue from "vue";
import Vuex from "vuex";

/**
 * -----------------------------------------------------------------------------------------------------------
 * Loading vuex modules
 * -----------------------------------------------------------------------------------------------------------
 */
import account from "@/views/account/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    path: "",
    full_path: "",
    email_reset_password: "",
    customerCareCount: 0, // Số lượng khách hàng chờ gán chăm sóc
    customerCounselorCount: 0,
    listOrg: [],
    organization_id: ""
  },
  getters: {
    path: (state) => state.path,
    full_path: (state) => state.full_path,
    email_reset_password: (state) => state.email_reset_password,
    customerCareCount: (state) => state.customerCareCount,
    customerCounselorCount: (state) => state.customerCounselorCount,
    listOrg: (state) => state.listOrg,
    organization_id: (state) => state.organization_id,
  },
  mutations: {
    setPath(state, path) {
      state.path = path;
    },
    setFullPath(state, full_path) {
      state.full_path = full_path;
    },
    setEmailResetPassword(state, email_reset_password) {
      state.email_reset_password = email_reset_password;
    },
    setCustomerCareCount(state, customerCareCount) {
      state.customerCareCount = customerCareCount;
    },
    setCustomerCounselorCount(state, customerCounselorCount) {
      state.customerCounselorCount = customerCounselorCount;
    },
    setListOrg(state, listOrg) {
      state.listOrg = listOrg;
    },
    setOrganization(state, organization_id) {
      state.organization_id = organization_id;
    },
  },
  actions: {
    setPath({ commit }, path) {
      commit("setPath", path);
    },
    setFullPath({ commit }, full_path) {
      commit("setFullPath", full_path);
    },
    setEmailResetPassword({ commit }, email_reset_password) {
      commit("setEmailResetPassword", email_reset_password);
    },
    setCustomerCareCount({ commit }, customerCareCount) {
      commit("setCustomerCareCount", customerCareCount);
    },
    setCustomerCounselorCount({ commit }, customerCounselorCount) {
      commit("setCustomerCounselorCount", customerCounselorCount);
    },
    setListOrg({ commit }, listOrg) {
      commit("setListOrg", listOrg);
    },
    setOrganization({ commit }, organization_id) {
      commit("setOrganization", organization_id);
    },
  },
  modules: {
    account,
  },
});
