<template>
  <div class="home-index frame-content-common-2022 order-report" id="ST2022">
    <div class="search-top-order d-flex align-items-center">
      <!-- <div class="width50">
        <span class="title-rp-order">Thống kê nhanh</span>
        <div class="pt-2">
          <div class="d-flex align-items-center">
            <div
              v-for="i in listStatus"
              :key="i.value"
              @click="changeStatus(i)"
            >
              <div class="title" :class="[i.value === filter.time ? 'activeStatus' : '']">
                <span>{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="width50">
        <span class="title-rp-order">Tìm kiếm nhanh</span>
        <div class="pt-2 d-flex align-items-center">
          <div class="input-search-content">
            <el-input v-model="filter.customer_search" placeholder="Nhập tên KH, mã KH, số điện thoại..."></el-input>
          </div>
          <div
            class="frame-content-search d-flex align-items-center"
            @click="fetchDataKT()"
          >
            <img src="@/static/icon/Dashboard/ceo/search.svg" />
            <span class="pl-2">Tìm kiếm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Báo cáo -->
    <div v-loading="loading" class="report-info">
      <div class="content-report-frame-2023 mt-3" v-if="listData.arr_report_customer">
        <div class="content-box-report">
          <span class="numberReport color3">{{ listData.arr_report_customer.total_balance_amount | vnd }}</span>
          <span class="reportInfo">Tổng số dư tài khoản khách hàng</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color4">{{ listData.arr_order_customer.total_remainder_fee | vnd }}</span>
          <span class="reportInfo">Tổng tiền hàng còn lại phải thu</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color2">{{ listData.arr_order_package.total_transport_need_to_collect | vnd }}</span>
          <span class="reportInfo">Tổng tiền vận chuyển phải thu</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color6">{{ listData.arr_delivery.total_remainder_code | vnd }}</span>
          <span class="reportInfo">Tổng tiền COD phải thu</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color5">{{ listData.arr_service.total_service | vnd }}</span>
          <span class="reportInfo">Tổng doanh thu dịch vụ</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color1">{{ listData.arr_negotiate.total_negotiate | vnd }}</span>
          <span class="reportInfo">Tổng doanh thu đàm phán</span>
        </div>
      </div>
      <!--  -->
      <div class="content-report-frame-2023" v-if="listData.arr_fine">
        <div class="content-box-report">
          <span class="numberReport color3">{{ listData.arr_fine.total_fine_amount | vnd }}</span>
          <span class="reportInfo">Tổng tiền phạt nhân viên</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color4">{{ listData.arr_compensation.total_compensation | vnd }}</span>
          <span class="reportInfo">Tổng tiền bồi thường của khách hàng</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color2">{{ listData.arr_fund.total_nap | vnd }}</span>
          <span class="reportInfo">Tổng tiền thu</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color6">{{ listData.arr_fund.total_chi | vnd }}</span>
          <span class="reportInfo">Tổng tiền chi</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color5">{{ listData.arr_order_package_2.total_transport_done | vnd }}</span>
          <span class="reportInfo">Tổng doanh thu vận chuyển</span>
        </div>
      </div>
    </div>
    <!-- Biểu đồ -->
    <div class="chart-line-wasehouse">
      <span class="title">Biểu đồ thu chi</span>
      <div class="d-flex justify-content-between height401 py-3">
        <!-- <el-select
          v-model="input"
          placeholder="Theo tháng"
          class="select-date frame-select-common-2022"
          popper-class="frame-select-popper-common-2022"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
        <!-- <div>
          <span class="detail">Thống kê chi tiết</span>
        </div> -->
      </div>
      <highcharts class="chart" :options="chartThuChi"></highcharts>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Chart} from 'highcharts-vue'
export default {
  name: "AccountantReport",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng Trung Quốc",
    };
  },
  components: {
    highcharts: Chart 
  },
  data() {
    return {
      loading: true,
      input: "",
      options: [
        {
          value: 'day',
          label: 'Theo ngày'
        },
        {
          value: 'month',
          label: 'Theo tháng'
        },
      ],
      filter: {
        time: "d",
        customer_search: "",
      },
      listStatus: [
        {
          name: "Trong ngày",
          value: "d",
        },
        {
          name: "Trong tuần",
          value: "w",
        },
        {
          name: "Tháng",
          value: "m",
        },
        {
          name: "Quý",
          value: "q",
        },
        {
          name: "Năm",
          value: "y",
        },
      ],
      // biểu đồ
      chartThuChi: {
        chart: {
          type: 'line'
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0,
          padding: 20,
        },
        series: [],
      },
      listData: {},
      listChart: {},
    };
  },
  mounted() {
    this.scrollTopST();
    this.fetchDataKT();
    this.fetchChart();
  },
  methods: {
    scrollTopST() {
      setTimeout(() => {
        document.getElementById("ST2022").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    // lọc
    changeStatus(item) {
      this.filter.time = item.value;
      this.fetchDataKT();
    },
    // lấy dữ liệu
    fetchDataKT() {
      this.loading = true;
      let filter = {
        time: this.filter.time,
        user_type: "9",
        customer_search: this.filter.customer_search
      }
      axios.get("statistic/fast", { params: filter }).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.listData = response.data;
            break;
          case 422:
            this.$notify.error({
              title: "Lỗi",
              message: response.data.error,
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // biểu đồ 
    fetchChart() {
      let filter  = {
        user_type: "9",
      }
      axios.get("statistic/slow", { params: filter }).then((response) => {
        switch (response.code) {
          case 200:
            this.listChart = response.data;
            this.chartThuChi.series = response.data.chart_user_type_9.chart_month;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.home-index.frame-content-common-2022 {
  background: #F7F8FF;
}
/* search top  */
.search-top-order {
  padding: 0 35px;
}
.search-top-order .width50 {
  width: 50%;
}
.search-top-order .width50 .title {
  width: 135px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000728;
  cursor: pointer;
}
.activeStatus {
  background: #E8F3FF;
  border-radius: 5px;
  border-bottom: 2px solid #45B4CE;
}
.title-rp-order {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #207CDC;
}
/* phần báo cáo  */
.order-report .content-report-frame-2023 {
  display: flex;
  align-items: center;
}
.order-report .numberReport {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.order-report .content-box-report {
  width: 20%;
  height: 110px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 6px 8px 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.order-report .reportInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000728;
  margin-top: 12px;
}
.order-report .detail-report {
  margin-top: 11px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #767676;
  cursor: pointer;
}
.order-report .detail-report:hover > span {
  color: #0688EE;
}
.order-report .detail-report img {
  margin-right: 10px;
}
.order-report .weight-report {
  padding: 5px 0 11px 0;
}
.order-report .weight-report span {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3F92D1;
  background: #F7F8FF;
  padding: 3px 6px;
}
</style>
<style>
/* Phần search  */
.order-report .input-search-content {
  width: 80%;
}
.order-report .input-search-content input {
  background: #FFFFFF;
  border: 1px solid #3F92D1;
  border-radius: 8px;
  height: 36px;
}
.order-report .frame-content-search {
  justify-content: center;
  width: 20%;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
  background: #0688EE;
  border-radius: 8px;
  width: 120px;
  height: 36px;
  color: #fff;
}
/* Biểu đồ  */
.order-report .highcharts-container svg {
  font-family: 'Roboto', sans-serif !important;
}
.order-report .highcharts-credits {
  display: none;
}
.order-report .chart-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
.height40 {
  height: 40px;
  padding: 10px 20px;
}
.chart-line-wasehouse {
  width: 100%;
  background: #fff;
  padding-top: 22px;
  margin: 10px;
}
.chart-line-wasehouse .title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
/* màu số  */
.color1 {
  color: #207CDC;
}
.color2 {
  color: #F09920;
}
.color3 {
  color: #26B01C;
}
.color4 {
  color: #DF4747;
}
.color5 {
  color: #45B4CE;
}
.color6 {
  color: #ED18BE;
}
/*  */
.height40 {
  height: 40px;
  padding: 10px 20px;
}
.height40 .detail {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #767676;
  cursor: pointer;
}
.order-report .select-date {
  width: auto;
}
.order-report .select-date input {
  width: 110px;
  height: 28px;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #767676;
}
.order-report .select-date .el-input__icon {
  line-height: 20px;
}
</style>
