<template>
  <div class="home-index frame-content-common-2022 order-report warehouseRP" id="ST2022">
    <div class="search-top-order d-flex align-items-center">
      <div class="width50">
        <span class="title-rp-order">Thống kê nhanh</span>
        <div class="pt-2">
          <div class="d-flex align-items-center">
            <div
              v-for="i in listStatus"
              :key="i.value"
              @click="changeStatus(i)"
            >
              <div class="title" :class="[i.value === filter.time ? 'activeStatus' : '']">
                <span>{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width50">
        <span class="title-rp-order">Tìm kiếm nhanh</span>
        <div class="pt-2 d-flex align-items-center">
          <div class="input-search-content">
            <el-input v-model="filter.customer_search" placeholder="Nhập tên KH, mã KH, số điện thoại..."></el-input>
          </div>
          <div
            class="frame-content-search d-flex align-items-center"
            @click="fetchDataWarehouseTransit()"
          >
            <img src="@/static/icon/Dashboard/ceo/search.svg" />
            <span class="pl-2">Tìm kiếm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Báo cáo -->
    <div v-loading="loading" class="report-info-warehouseTransit">
      <div class="content-report-frame-2023 mt-3" v-if="listData.arr_warehouse">
        <div class="content-box-report">
          <span class="numberReport color3 warehouse-transit" @click="packetDetail('total_pack_receive')">{{ listData.arr_warehouse.total_pack_receive | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng nhận hàng</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color1 warehouse-transit" @click="packetDetail('status_6')">{{ listData.arr_warehouse.total_pack_delivered | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng đã giao</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color2 warehouse-transit" @click="packetDetail('status_8')">{{ listData.arr_warehouse.total_pack_waiting_delivery | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng chờ giao</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color6 warehouse-transit" @click="packetDetail('total_pack_confirmation_warehouse')">{{ listData.arr_warehouse.total_pack_confirmation_warehouse | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng chờ xác nhận từ kho</span>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Biểu đồ -->
    <div class="d-flex justify-content-center">
      <div class="chart-customer-column">
        <span class="chart-title">Tổng trọng lượng kiện hàng nhận</span>
        <div class="d-flex align-items-center height40">
          <el-select
            v-model="filter.timePackKG"
            placeholder="Theo tháng"
            class="select-date frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
          >
            <el-option
              v-for="item in timeChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <highcharts class="chart" :options="chartPackKg"></highcharts>
      </div>
      <div class="chart-customer-column">
        <span class="chart-title">Tổng thể tích kiện hàng nhận</span>
        <div class="d-flex align-items-center height40">
          <el-select
            v-model="filter.timePackM3"
            placeholder="Theo tháng"
            class="select-date frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
          >
            <el-option
              v-for="item in timeChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <highcharts class="chart" :options="chartPackM3"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Chart} from 'highcharts-vue'
export default {
  name: "WasehouseTransit",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng Trung Quốc",
    };
  },
  components: {
    highcharts: Chart
  },
  data() {
    return {
      loading: true,
      input: "",
      timeChart: [
        {
          value: 'day',
          label: 'Theo ngày'
        },
        {
          value: 'month',
          label: 'Theo tháng'
        },
      ],
      filter: {
        time: "d",
        timePackKG: "month",
        timePackM3: "month",
      },
      listStatus: [
        {
          name: "Trong ngày",
          value: "d",
        },
        {
          name: "Trong tuần",
          value: "w",
        },
        {
          name: "Tháng",
          value: "m",
        },
        {
          name: "Quý",
          value: "q",
        },
        {
          name: "Năm",
          value: "y",
        },
      ],
      // biểu đồ trọng lượng kiện hàng nhận
      chartPackKg: {
        chart: {
          type: 'line'
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: 'Trọng lượng ( Kg )'
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        legend: {
          enabled: false
        },
        series:[{
              name: '',
              data: []
          },
        ],
      },
    // biểu đồ thể tích kiện hàng nhận
    chartPackM3: {
        chart: {
          type: 'line'
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: 'Thể tích ( M3 )',
                style: {
                  fonfamily: "roboto",
                }
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        legend: {
          enabled: false
        },
        series:[{
              name: '',
              data: []
          },
        ],
      },
    // biểu đồ đặt hàng theo chi nhánh
    chartWasehouse: {
        chart: {
          type: 'line'
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0,
        },
        // legend: {
        //   enabled: false
        // },
        series:[{
              name: 'Chi nhánh 1',
              data: [43934, 48656, 65165, 81827, 112143, 142383,
                  171533, 165174, 155157, 161454, 154610, 221001]
          },
          {
            name: 'Chi nhánh 2',
              data: [13934, 788656, 65165, 21827, 12143, 342383,
                  171533, 365174, 155157, 161454, 754610, 221001]
          },
        ],
      },
      // dữ liệu thống kê
      listData: {},
      listChart: {},
      dataMonthchart: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
      dataDayChart: ['1','2','3','4','5','6','7','8','9','10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    };
  },
  mounted() {
    this.scrollTopST();
    this.fetchDataWarehouseTransit();
    this.fetchChart()
  },
  methods: {
    scrollTopST() {
      setTimeout(() => {
        document.getElementById("ST2022").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    // lọc
    changeStatus(item) {
      this.filter.time = item.value;
      this.fetchDataWarehouseTransit();
    },
    // dữ liệu thống kê
    fetchDataWarehouseTransit() {
      this.loading = true;
      let filter = {
        time: this.filter.time,
        user_type: "12",
        customer_search: this.filter.customer_search
      }
      axios.get("statistic/fast", { params: filter }).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.listData = response.data;
            break;
          case 422:
            this.$notify.error({
              title: "Lỗi",
              message: response.data.error,
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // lấy data biểu đồ
    fetchChart() {
      let filter  = {
        user_type: "12",
      }
      axios.get("statistic/slow", { params: filter }).then((response) => {
        switch (response.code) {
          case 200:
            this.listChart = response.data;
            this.chartPackKg.series[0].data = response.data.chart_user_type_12.pack_pack_receive_kg.chart_month;
            this.chartPackM3.series[0].data = response.data.chart_user_type_12.pack_pack_receive_m3.chart_month;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    packetDetail(item) {
      let routeData = this.$router.resolve({name: 'pack-list', query: {value: item, time: this.filter.time}});
      window.open(routeData.href, '_blank');
    }
  },
  watch: {
    // chọn thời gian bd trọng lượng
    "filter.timePackKG": function () {
      if(this.filter.timePackKG == 'day') {
        this.chartPackKg.xAxis.categories = this.dataDayChart;
        this.chartPackKg.series[0].data = this.listChart.chart_user_type_12.pack_pack_receive_kg.chart_day
      } else {
        this.chartPackKg.xAxis.categories = this.dataMonthchart
        this.chartPackKg.series[0].data = this.listChart.chart_user_type_12.pack_pack_receive_kg.chart_month
      }
    },
    // chọn thời gian bd thể tích
    "filter.timePackM3": function () {
      if(this.filter.timePackM3 == 'day') {
        this.chartPackM3.xAxis.categories = this.dataDayChart;
        this.chartPackM3.series[0].data = this.listChart.chart_user_type_12.pack_pack_receive_m3.chart_day
      } else {
        this.chartPackM3.xAxis.categories = this.dataMonthchart
        this.chartPackM3.series[0].data = this.listChart.chart_user_type_12.pack_pack_receive_m3.chart_month
      }
    }
  },
};
</script>

<style lang="css" scoped>
.home-index.frame-content-common-2022 {
  background: #F7F8FF;
}
/* search top  */
.search-top-order {
  padding: 0 35px;
}
.search-top-order .width50 {
  width: 50%;
}
.search-top-order .width50 .title {
  width: 135px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000728;
  cursor: pointer;
}
.activeStatus {
  background: #E8F3FF;
  border-radius: 5px;
  border-bottom: 2px solid #45B4CE;
}
.title-rp-order {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #207CDC;
}
/* phần báo cáo  */
.order-report .content-report-frame-2023 {
  display: flex;
  align-items: center;
}
.order-report .numberReport {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.order-report .content-box-report {
  width: 25%;
  height: 110px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px 6px 8px 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.order-report .reportInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000728;
  margin-top: 12px;
}
.order-report .detail-report {
  margin-top: 11px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #767676;
  cursor: pointer;
}
.order-report .detail-report:hover > span {
  color: #0688EE;
}
.order-report .detail-report img {
  margin-right: 10px;
}
.order-report .weight-report {
  padding: 5px 0 11px 0;
}
.order-report .weight-report span {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3F92D1;
  background: #F7F8FF;
  padding: 3px 6px;
}
</style>
<style>
/* Phần search  */
.order-report .input-search-content {
  width: 80%;
}
.order-report .input-search-content input {
  background: #FFFFFF;
  border: 1px solid #3F92D1;
  border-radius: 8px;
  height: 36px;
}
.order-report .frame-content-search {
  justify-content: center;
  width: 20%;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
  background: #0688EE;
  border-radius: 8px;
  width: 120px;
  height: 36px;
  color: #fff;
}
/* biểu đồ  */
.order-report.warehouseRP .chart-customer-column {
  width: 50%;
  background: #fff;
  /* margin-right: 22px; */
  padding-top: 22px;
  margin: 10px;
}
.order-report .highcharts-container svg {
  font-family: 'Roboto', sans-serif !important;
}
.order-report .highcharts-credits {
  display: none;
}
.order-report .chart-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
/* chú thích biểu đồ  */
.height40 {
  height: 40px;
  padding: 10px 20px;
}
.height40 .icon {
  width: 12px;
  height: 12px;
  background: #F09920;
  border-radius: 50%;
}
.height40 .text {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000728;
  padding-left: 6px;
}
.chart-line-wasehouse {
  width: 100%;
  background: #fff;
  padding-top: 22px;
  margin: 10px;
}
.chart-line-wasehouse .title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
/*  */
.order-report .select-date {
  width: auto;
}
.select-date input {
  width: 110px;
  height: 28px;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #767676;
}
.select-date .el-input__icon {
  line-height: 20px;
}
/* màu số  */
.color1 {
  color: #207CDC;
}
.color2 {
  color: #F09920;
}
.color3 {
  color: #26B01C;
}
.color4 {
  color: #DF4747;
}
.color5 {
  color: #45B4CE;
}
.color6 {
  color: #ED18BE;
}
.height40 .detail {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #767676;
  cursor: pointer;
}
.report-info-warehouseTransit {
  min-height: 200px;
}
.warehouse-transit:hover {
  cursor: pointer;
  color: #0b859f !important;
}
</style>
