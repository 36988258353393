import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/account/ForgotPassword.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/account/ChangePassword.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/change-password-success",
    name: "change-password-success",
    component: () => import("@/views/account/ChangePasswordSuccess.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer",
    name: "customer",
    redirect: "/customer/customer-lists",
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists",
    name: "customer-lists",
    component: () => import("@/views/customer/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists/wait-for-assignment-care",
    name: "customer-wait-for-assignment-care",
    component: () => import("@/views/customer/staff/Care.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists/wait-for-assignment-counselor",
    name: "customer-wait-for-assignment-counselor",
    component: () => import("@/views/customer/staff/Counselor.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists/create",
    name: "customer-lists-create",
    component: () => import("@/views/customer/ListCreate.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists/update/:id",
    name: "customer-lists-update",
    component: () => import("@/views/customer/ListUpdate.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-lists/detail/:id",
    name: "customer-lists-detail",
    component: () => import("@/views/customer/Detail.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-prices",
    name: "customer-prices",
    component: () => import("@/views/customer/Price.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-potentials",
    name: "customer-potentials",
    component: () => import("@/views/customer/Potential.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/change-staff-requests",
    name: "change-staff-requests",
    component: () => import("@/views/customer/ChangeStaffRequest.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/customer-levels",
    name: "customer-levels",
    component: () => import("@/views/customer/Level.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator",
    name: "administrator",
    redirect: "/administrator/user-management",
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator/user-management",
    name: "user-management",
    component: () => import("@/views/administrator/User.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator/organization-management",
    name: "organization-management",
    component: () => import("@/views/administrator/Organization.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator/department-management",
    name: "department-management",
    component: () => import("@/views/administrator/Department.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator/role-management",
    name: "role-management",
    component: () => import("@/views/administrator/Role.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/administrator/group-management",
    name: "group-management",
    component: () => import("@/views/group/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Đơn hàng
  {
    path: "/order-list",
    name: "order-list",
    component: () => import("@/views/order/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order-list/update/:order_id",
    name: "order-update",
    component: () => import("@/views/order/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order-list/detail/:order_id",
    name: "order-detail",
    component: () => import("@/views/order/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order-list/add-pack/:order_id",
    name: "order-add-pack",
    component: () => import("@/views/order/AddPack.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order-list/update-pack/:pack_id",
    name: "order-update-pack",
    component: () => import("@/views/order/UpdatePack.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ĐẶT HÀNG
  {
    path: "/place-order",
    name: "place-order",
    component: () => import("@/views/place-order/index.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Đơn hàng hỗ trợ
  {
    path: "/place-order-support",
    name: "place-order-support",
    component: () => import("@/views/place-order/OrderSupport.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Đơn hàng hỗ trợ
  {
    path: "/place-order-payment",
    name: "place-order-payment",
    component: () => import("@/views/place-order/Payment.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/place-order-cost",
    name: "place-order-cost",
    component: () => import("@/views/actual-amount/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ewallet
  {
    path: "/ewallet-list",
    name: "ewallet-list",
    component: () => import("@/views/ewallet/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // package
  {
    path: "/pack/list",
    name: "pack-list",
    component: () => import("@/views/pack/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack/create",
    name: "pack-create",
    component: () => import("@/views/pack/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack/detail/:pack_id",
    name: "pack-detail",
    component: () => import("@/views/pack/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack/cancel",
    name: "pack-cancel",
    component: () => import("@/views/pack/Cancel.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Màn trả hàng
  {
    path: "/pack/receive-goods",
    name: "pack-receive-goods",
    component: () => import("@/views/pack/ListReceiveGoods.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Thống kê phiếu xác nhận trả hàng
  {
    path: "/pack/statistical-print",
    name: "pack-statistical-print",
    component: () => import("@/views/statistical/ListPrint.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Hàng lỗi hoàn hàng
  {
    path: "/pack/refuns",
    name: "pack-refuns",
    component: () => import("@/views/pack-refund/RefundList.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  //
  {
    path: "/pack/wait-confirm",
    name: "pack-wait-confirm",
    component: () => import("@/views/pack-wait-confirm/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Khiếu nại
  {
    path: "/complain-list",
    name: "complain-list",
    component: () => import("@/views/complain/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/complain-list/detail/:complain_id",
    name: "complain-detail",
    component: () => import("@/views/complain/Detail.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Giao hàng
  {
    path: "/delivery-list",
    name: "delivery-list",
    component: () => import("@/views/delivery/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/delivery-list/create",
    name: "delivery-create",
    component: () => import("@/views/delivery/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/delivery-list/update/:delivery_id",
    name: "delivery-update",
    component: () => import("@/views/delivery/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/delivery-list/bill/:delivery_id",
    name: "delivery-bill",
    component: () => import("@/views/delivery/Bill.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/delivery-note/:delivery_id",
    name: "delivery-note",
    component: () => import("@/views/delivery/Note.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/delivery-detail/:delivery_id",
    name: "delivery-detail",
    component: () => import("@/views/delivery/Detail.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Kế toán
  {
    path: "/accountant",
    name: "accountant",
    redirect: "/accountant/fund-transaction-list-vn",
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fund-list",
    name: "fund-list",
    component: () => import("@/views/fund/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/fund-recharge",
    name: "fund-recharge",
    component: () => import("@/views/fund/Recharge.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/fund-transaction-list-vn",
    name: "fund-transaction-list-vn",
    component: () => import("@/views/fund-transaction/ListVN.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/fund-transaction-list-cn",
    name: "fund-transaction-list-cn",
    component: () => import("@/views/fund-transaction/ListCN.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/fund-withdrawal",
    name: "fund-withdrawal",
    component: () => import("@/views/fund/Withdrawal.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/fund-ncc",
    name: "fund-ncc",
    component: () => import("@/views/fund-transaction/ListNCC.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/accountant/revenue-expenditure",
    name: "revenue-expenditure",
    component: () => import("@/views/fund-transaction/ReportRevenueExpenditure.vue"),
    beforeEnter: auth.requireAuth,
  },
  // nộp phạt
  {
    path: "/accountant/fines-lists",
    name: "fines-lists",
    component: () => import("@/views/fines/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fines-lists/update/:fine_id",
    name: "fines-update",
    component: () => import("@/views/fines/Update.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fines-create",
    name: "fines-create",
    component: () => import("@/views/fines/Create.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fund-debt",
    name: "fund-debt",
    component: () => import("@/views/customer/ListDebt.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant-fund-transaction-print/:id",
    name: "fund-transaction-print",
    component: () => import("@/views/fund-transaction/Print.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fund-transaction-deleted",
    name: "fund-transaction-deleted",
    component: () => import("@/views/fund-transaction/Deleted.vue"),
    beforeEnter: auth.requireAuth,
  },


  // Cài đặt chung
  {
    path: "/setting",
    name: "setting",
    redirect: "/setting/profile",
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/setting/profile",
    name: "profile-user",
    component: () => import("@/views/others/Profile.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/accountant/fund-type-pay-list",
    name: "fund-type-pay-list",
    component: () => import("@/views/fund-type-pay/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/setting/trash-customer",
    name: "trash-customer",
    component: () => import("@/views/trash/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/setting/complain",
    name: "setting-complain",
    component: () => import("@/views/complain/AutoMessage.vue"),
    beforeEnter: auth.requireAuth,
  },
  // màn thay đổi giá trị
  {
    path: "/setting/changes-in-value",
    name: "changes-in-value",
    component: () => import("@/views/changes-value/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/setting/config-address-receivers",
    name: "config-address-receivers",
    component: () => import("@/views/config-address-receivers/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/setting/config-reason-cancel-order",
    name: "config-reason-cancel-order",
    component: () => import("@/views/config-reason-cancel-order/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/setting/config-shipping-estimated-fee",
    name: "config-shipping-estimated-fee",
    component: () => import("@/views/config/config-shipping-estimated-fee/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  // màn nhúng iframe
  {
    path: "/shipping-value",
    name: "shipping-value",
    component: () => import("@/views/changes-value/iframe.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo
  {
    path: "/report",
    name: "report",
    redirect: "/report-potential-customer-management",
    beforeEnter: auth.requireAuthV2,
  },
  // Đánh giá
  {
    path: "/report-rate",
    name: "report-rate",
    component: () => import("@/views/report/ListOrderRate.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  //tư vấn quản lý khách hàng tiềm năng
  {
    path: "/report-potential-customer-management",
    name: "potential-customer-management",
    component: () => import("@/views/report/ListQLKHTM.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // chăm sóc khách hàng đang hoạt động
  {
    path: "/report-customer-active",
    name: "report-customer-active",
    component: () => import("@/views/report/ListKHDHD.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo đơn hàng hoàn thành
  {
    path: "/report-order-complete",
    name: "report-order-complete",
    component: () => import("@/views/report/OrderComplete.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo khách hàng theo đơn
  {
    path: "/report-customer-order",
    name: "report-customer-order",
    component: () => import("@/views/report/CustomerOrder.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo khách hàng theo kiện về VN
  {
    path: "/report-customer-pack-vn",
    name: "report-customer-pack-vn",
    component: () => import("@/views/report/CustomerPackageVN.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Hoàn tiền
  {
    path: "/refund",
    name: "refund",
    component: () => import("@/views/refund/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // ứng tiền
  {
    path: "/accountant/advance-money",
    name: "accountant-advance-money",
    component: () => import("@/views/order/advance-money/List.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // hoạt động báo giá
  {
    path: "/report-staff-quotation",
    name: "report-staff-quotation",
    component: () => import("@/views/report/StaffQuotation.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // hoạt động đặt hàng
  {
    path: "/report-staff-order",
    name: "report-staff-order",
    component: () => import("@/views/report/StaffOrder.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/report-sale",
    name: "report-sale",
    component: () => import("@/views/report/ListSale.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/report-transaction",
    name: "report-transaction",
    component: () => import("@/views/report/ListTransaction.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // hiệu suất nhân viên tư vấn, chăm sóc
  {
    path: "/report-staff-care-counselor",
    name: "report-staff-care-counselor",
    component: () => import("@/views/report/StaffCareCounselor.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Thống kê nhanh kênh mua hàng
  {
    path: "/report-purchase-channel",
    name: "report-purchase-channel",
    component: () => import("@/views/report/ListChannel.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Thống kê nhanh phí vận chuyển
  {
    path: "/report-transport-fee",
    name: "report-transport-fee",
    component: () => import("@/views/report/TransportFee.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Báo cáo theo tuần
  {
    path: "/report-order-customer-week",
    name: "report-order-customer-week",
    component: () => import("@/views/report/ReportCustomerOrderWeek.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Báo cáo đối soát marketing
  {
    path: "/report-control-marketing",
    name: "report-control-marketing",
    component: () => import("@/views/report/ReportControlMarketingV2.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // {
  //   path: "/report-control-marketing-v2",
  //   name: "report-control-marketing-v2",
  //   component: () => import("@/views/report/ReportControlMarketingV2.vue"),
  //   beforeEnter: auth.requireAuthV2,
  // },
  // Báo cáo data phòng và tỷ lệ chốt đơn
  {
    path: "/report-closing-rate",
    name: "report-closing-rate",
    component: () => import("@/views/report/ReportClosingRate.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo đặt hàng
  {
    path: "/place-order/buyder",
    name: "place-order-buyder",
    component: () => import("@/views/report/Ordered.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo đặt hàng
  {
    path: "/report-customer-order-sale-staff",
    name: "report-customer-order-sale-staff",
    component: () => import("@/views/report/ReportCustomerOrderSaleStaff.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo data và tỷ lệ chốt nhân viên
  {
    path: "/report-closing-rate-staff",
    name: "report-closing-rate-staff",
    component: () => import("@/views/report/ReportClosingRateStaff.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo doanh số nhân viên kinh doanh
  {
    path: "/report-sale-staff",
    name: "report-sale-staff",
    component: () => import("@/views/report/SaleStaff.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Báo cáo theo tỉnh thành phố
  {
    path: "/report-province",
    name: "report-province",
    component: () => import("@/views/report/ReportProvince.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo thống kê request trong pipeline: tìm kiếm, thêm vào giỏ hàng, lên đơn, đặt hàng
  // 1. báo cáo thống kê qua form
  {
    path: "/report-products-pipeline-form",
    name: "report-products-pipeline-form",
    component: () => import("@/views/report/request/Form.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // 2. báo cáo kết quả tìm kiếm
  {
    path: "/report-products-pipeline-search",
    name: "report-products-pipeline-search",
    component: () => import("@/views/report/request/Search.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // 3. báo cáo kết quả qua từ khóa khách hàng
  {
    path: "/report-products-pipeline-customer",
    name: "report-products-pipeline-customer",
    component: () => import("@/views/report/request/Customer.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // báo cáo hiệu xuất chốt đơn nhân viên
  {
    path: "/report-performance-sale-order",
    name: "report-performance-sale-order",
    component: () => import("@/views/report/PerformanceSaleOrder.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/customer/with-trashed",
    name: "customer-with-trashed",
    component: () => import("@/views/customer/WithTrashed.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/order/list-cancel",
    name: "order-list-cancel",
    component: () => import("@/views/order/ListCancel.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/pack/print-multiple",
    name: "pack-print-multiple",
    component: () => import("@/views/pack/PrintWarehouse.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/pack/print-multiple-all",
    name: "pack-print-multiple-all",
    component: () => import("@/views/pack/PrintWarehouseAll.vue"),
    beforeEnter: auth.requireAuthV2,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
