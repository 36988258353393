<template>
  <div id="ST2022">
    <div class="tabReport d-flex align-items-center">
      <span
        class="item"
        :class="tabActive == 'dashboard.type-1' ? 'active' : ''"
        @click="changeTab('dashboard.type-1')"
        v-if="hasPermission('dashboard.type-1')"
      >CEO</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-2' ? 'active' : ''"
        @click="changeTab('dashboard.type-2')"
        v-if="hasPermission('dashboard.type-2')"
      >TP đặt hàng</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-3' ? 'active' : ''"
        @click="changeTab('dashboard.type-3')"
        v-if="hasPermission('dashboard.type-3')"
      >NV báo giá</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-4' ? 'active' : ''"
        @click="changeTab('dashboard.type-4')"
        v-if="hasPermission('dashboard.type-4')"
      >NV đặt hàng</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-5' ? 'active' : ''"
        @click="changeTab('dashboard.type-5')"
        v-if="hasPermission('dashboard.type-5')"
      >TP kinh doanh</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-6' ? 'active' : ''"
        @click="changeTab('dashboard.type-6')"
        v-if="hasPermission('dashboard.type-6')"
      >NV kinh doanh</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-9' ? 'active' : ''"
        @click="changeTab('dashboard.type-9')"
        v-if="hasPermission('dashboard.type-9')"
      >Kế toán</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-10' ? 'active' : ''"
        @click="changeTab('dashboard.type-10')"
        v-if="hasPermission('dashboard.type-10')"
      >Marketing</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-11' ? 'active' : ''"
        @click="changeTab('dashboard.type-11')"
        v-if="hasPermission('dashboard.type-11')"
      >Kho trung quốc</span>
      <span
        class="item"
        :class="tabActive == 'dashboard.type-12' ? 'active' : ''"
        @click="changeTab('dashboard.type-12')"
        v-if="hasPermission('dashboard.type-12')"
      >Kho trung chuyển</span>
      <span
        class="item"
        :class="tabActive == ('dashboard.type-13' || 'dashboard.type-13-1' || 'dashboard.type-13-2' || 'dashboard.type-13-4') ? 'active' : ''"
        @click="changeTab('dashboard.type-13' || 'dashboard.type-13-1' || 'dashboard.type-13-2' || 'dashboard.type-13-4')"
        v-if="hasPermission('dashboard.type-13') || hasPermission('dashboard.type-13-1') || hasPermission('dashboard.type-13-2') || hasPermission('dashboard.type-13-4')"
      >Kho chi nhánh</span>
      <!-- chi nhánh kho -->
      <!-- <div v-if="tabActive == 13">
        <el-select
          v-model="selectWH"
          placeholder="Chọn chi nhánh"
          class="frame-select-common-2022 whSelect"
          popper-class="frame-select-popper-common-2022"
        >
          <el-option
            v-for="item in optionWHBranch"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
    </div>
    <!--  -->
    <div v-if="tabActive == 'dashboard.type-1'">
      <IndexCeo v-if="hasPermission('dashboard.type-1')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-2'">
      <orderManager v-if="hasPermission('dashboard.type-2')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-3'">
      <orderQuoteStaff v-if="hasPermission('dashboard.type-3')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-4'">
      <orderStaff v-if="hasPermission('dashboard.type-4')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-5'">
      <counseManager v-if="hasPermission('dashboard.type-5')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-6'">
      <counselor v-if="hasPermission('dashboard.type-6')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-9'">
      <accountantrp v-if="hasPermission('dashboard.type-9')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-10'">
      <Maketing v-if="hasPermission('dashboard.type-10')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-11'">
      <wareHouseCN v-if="hasPermission('dashboard.type-11')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-12'">
      <wareHouseTransit v-if="hasPermission('dashboard.type-12')" />
    </div>
    <div v-if="tabActive == 'dashboard.type-13' && 'dashboard.type-13-1' && 'dashboard.type-13-2' && 'dashboard.type-13-4'">
      <wareHouseBranch v-if="hasPermission('dashboard.type-13') || hasPermission('dashboard.type-13-1') || hasPermission('dashboard.type-13-2') || hasPermission('dashboard.type-13-4')" />
    </div>
    <!--  -->
  </div>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";
import IndexCeo from "@/views/home/ceo/Index.vue";
import orderManager from "@/views/home/order/Index-order-manager.vue";
import orderStaff from "@/views/home/order/Index-order-staff.vue";
import orderQuoteStaff from "@/views/home/order/Index-quote-staff.vue";
import counseManager from "@/views/home/counsel/Index-consulting-manager.vue";
import counselor from "@/views/home/counsel/Index-counselor.vue";
import accountantrp from "@/views/home/accountantrp/Index.vue";
import Maketing from "@/views/home/maketing/Index.vue";
import wareHouseCN from "@/views/home/warehouse/Warehouse-cn.vue";
import wareHouseTransit from "@/views/home/warehouse/Warehouse-transit.vue";
import wareHouseBranch from "@/views/home/warehouse/Warehouse-branch.vue";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng Trung Quốc",
    };
  },
  components: {
    IndexCeo, //CEO
    orderManager, // TP đặt hàng
    orderStaff, // nv đặt hàng
    orderQuoteStaff, // NV báo giá
    counseManager, // TP kinh doanh
    counselor, // NV kinh doanh
    accountantrp, // Kế toán
    Maketing, // marketing
    wareHouseCN, // Kho trung quốc
    wareHouseTransit, // Kho Trung chuyển
    wareHouseBranch, // Kho chi nhánh
  },
  data() {
    return {
      showDetail: true,
      loading: true,
      tabActive: "",
      selectWH: "branch1",
      optionWHBranch: [
        {
          value: 'branch1',
          label: 'chi nhánh 1'
        },
        {
          value: 'branch2',
          label: 'chi nhánh 2'
        },
      ],
      ListPer: [],
    };
  },
  mounted() {
    this.scrollTopST();
    this.hasPer();
  },
  computed: {
    ...mapGetters(["path", "full_path"]),
    ...mapGetters("account", ["accountInfo"]),

    permissions() {
      return this.accountInfo.permissions;
    }
  },
  methods: {
    hasPer() {
      let permissions2 = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions2.push(e.name);
        });
      }
      // if(permissions2.includes('dashboard.type-1', 'dashboard.type-2')) {
      //   this.ListPer.push(permissions2)
      // }
      permissions2.forEach((i) => {
        if('dashboard.type-1' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-2' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-3' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-4' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-5' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-6' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-7' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-8' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-9' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-10' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-11' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-12' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-13' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-13-1' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-13-2' == i) {
          this.ListPer.push(i);
        }
        if('dashboard.type-13-4' == i) {
          this.ListPer.push(i);
        }
      })
      this.tabActive = this.ListPer[0]
    },
    // check quyền
    hasPermission(permission) {
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      return permissions.includes(permission);
    },
    //
    scrollTopST() {
      setTimeout(() => {
        document.getElementById("ST2022").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    // chọn tab menu
    changeTab(tab) {
      this.tabActive = tab;
    },
    //
  },
  watch: {
    "selectWH" :function() {
      // this.selectWH = value;
      this.$emit('childToParent', this.selectWH)
    },
    accountInfo() {
        this.hasPer();
    }
  },
};
</script>

<style lang="css" scoped>
.tabReport {
  /* margin: 20px 0; */
  padding: 0 20px;
  background: #fff;
  z-index: 9;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.tabReport .item {
  padding: 20px 10px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.tabReport .item.active {
  color: #28b8ce;
}
</style>
<style>
.whSelect input {
  height: 30px !important;
  font-size: 12px;
}
.whSelect .el-input__icon {
  line-height: 30px;
}
</style>
