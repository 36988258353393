<template>
  <div class="home-index frame-content-common-2022 order-report" id="ST2022">
    <div class="select-padding">
      <el-select
        v-model="selectWH"
        placeholder="Chọn chi nhánh"
        class="frame-select-common-2022 whSelect"
        popper-class="frame-select-popper-common-2022"
      >
        <el-option
          v-for="item in listWarehouseNew"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="search-top-order d-flex align-items-center">
      <div class="width50">
        <span class="title-rp-order">Thống kê nhanh</span>
        <div class="pt-2">
          <div class="d-flex align-items-center">
            <div
              v-for="i in listStatus"
              :key="i.value"
              @click="changeStatus(i)"
            >
              <div class="title" :class="[i.value === filter.time ? 'activeStatus' : '']">
                <span>{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width50">
        <span class="title-rp-order">Tìm kiếm nhanh</span>
        <div class="pt-2 d-flex align-items-center">
          <div class="input-search-content">
            <el-input v-model="filter.customer_search" placeholder="Nhập tên KH, mã KH, số điện thoại..."></el-input>
          </div>
          <div
            class="frame-content-search d-flex align-items-center"
            @click="fetchDataWarehouseBranch()"
          >
            <img src="@/static/icon/Dashboard/ceo/search.svg" />
            <span class="pl-2">Tìm kiếm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Báo cáo -->
    <div v-loading="loading" class="report-info-whbranch">
      <div class="content-report-frame-2023 mt-3" v-if="listData.arr_warehouse">
        <div class="content-box-report">
          <span class="numberReport color3 warehouse-branch" @click="packetDetail('total_pack_in_warehouse')">{{ listData.arr_warehouse.total_pack_in_warehouse | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng trong kho</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color1 warehouse-branch" @click="packetDetail('total_pack_received')">{{ listData.arr_warehouse.total_pack_received | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng đã nhận</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color5 warehouse-branch" @click="packetDetail('total_pack_cod')">{{ listData.arr_warehouse.total_pack_cod | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng COD</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color6 warehouse-branch" @click="packetDetail('total_pack_waiting_confirm_branch')">{{ listData.arr_warehouse.total_pack_waiting_confirm | vnd2 }}</span>
          <span class="reportInfo">Tổng kiện hàng chờ xác nhận</span>
        </div>
      </div>
      <!--  -->
      <div class="report-2-warehouse d-flex" v-if="listData.arr_warehouse">
        <div class="percent50">
          <div class="icon">
            <img src="@/static/icon/Dashboard/warehouse/time.svg" />
          </div>
          <div class="info d-flex flex-column">
            <span class="number color2">{{ listData.arr_warehouse.total_kg_in_warehouse }}</span>
            <span class="title">Tổng trọng lượng hàng trong kho ( Kg ) </span>
          </div>
        </div>
        <div class="percent50">
          <div class="icon">
            <img src="@/static/icon/Dashboard/warehouse/bar.svg" />
          </div>
          <div class="info d-flex flex-column">
            <span class="number">{{ listData.arr_warehouse.total_m3_in_warehouse }}</span>
            <span class="title">Tổng thể tích hàng trong kho ( M3 )</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Biểu đồ -->
    <div class="chart-line-wasehouse">
      <span class="title">Biểu đồ hàng về theo ngày</span>
      <div class="d-flex justify-content-between height40">
        <el-select
          v-model="filter.timeChart"
          placeholder="Theo tháng"
          class="select-date frame-select-common-2022"
          popper-class="frame-select-popper-common-2022"
        >
          <el-option
            v-for="item in timeChart"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <!-- <div>
          <span class="detail">Thống kê chi tiết</span>
        </div> -->
      </div>
      <highcharts class="chart" :options="chartWasehouse"></highcharts>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {Chart} from 'highcharts-vue'
export default {
  name: "WasehouseBranch",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng Trung Quốc",
    };
  },
  components: {
    highcharts: Chart
  },
  data() {
    return {
      loading: true,
      input: "",
      // selectWH: "1eda5f97-9239-66e8-a055-00163e0109a7",
      selectWH: "",

      //-------
      timeChart: [
        {
          value: 'day',
          label: 'Theo ngày'
        },
        {
          value: 'month',
          label: 'Theo tháng'
        },
      ],
      filter: {
        time: "d",
        customer_search: "",
        timeChart: "month",
      },
      listStatus: [
        {
          name: "Trong ngày",
          value: "d",
        },
        {
          name: "Trong tuần",
          value: "w",
        },
        {
          name: "Tháng",
          value: "m",
        },
        {
          name: "Quý",
          value: "q",
        },
        {
          name: "Năm",
          value: "y",
        },
      ],
      // biểu đồ
    chartWasehouse: {
        chart: {
          type: 'line'
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        // legend: {
        //   enabled: false
        // },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0,
        },
        series:[{
              name: 'Số kiện',
              data: [43934, 48656, 65165, 81827, 112143, 142383,
                  171533, 165174, 155157, 161454, 154610, 221001],
              color: "#26B01C",
          },
        ],
      },
      // data
      listData: {},
      listChart: {},
      listWarehouse: [],
      idWarehouse: "",
      listWarehouseNew: [],
      listPerWh: [],
      dataMonthchart: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
      dataDayChart: ['1','2','3','4','5','6','7','8','9','10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    };
  },
  mounted() {
    this.scrollTopST();
    this.fetchWarehouse();
    // this.fetchDataWarehouseBranch();
    // this.fetchChart();
    this.fetchWhPer();
  },
  computed: {
    ...mapGetters(["path", "full_path"]),
    ...mapGetters("account", ["accountInfo"]),
  },
  methods: {
    fetchWhPer() {
      let permissionsV2 = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissionsV2.push(e.name);
        });
      }
      this.listPerWh = permissionsV2;
    },
    // check quyền
    hasPermission(permission) {
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      return permissions.includes(permission);
    },
    //
    scrollTopST() {
      setTimeout(() => {
        document.getElementById("ST2022").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    // lọc
    changeStatus(item) {
      this.filter.time = item.value;
      this.fetchDataWarehouseBranch();
    },
    // lấy dữ liệu thống kê
    fetchDataWarehouseBranch() {
      let filter = {};
      if (this.listWarehouseNew.length > 0) {
        filter.warehouse_id = this.selectWH;
        filter.user_type = "13";
        filter.customer_search =  this.filter.customer_search;
        filter.time = this.filter.time;
      }
      this.loading = true;
      axios.get("statistic/fast", { params: filter }).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.listData = response.data;
            break;
          case 422:
            this.$notify.error({
              title: "Lỗi",
              message: response.data.error,
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // lấy dữ liệu biểu đồ
    fetchWarehouse() {
      this.fetchWhPer();
      axios.get("statistic/list-warehouse").then((response) => {
        switch (response.code) {
          case 200:
            this.listWarehouse = response.data.arr_warehouses;
            if(this.listPerWh.includes('dashboard.type-13-1')) {
              let id1 = "1eda5f97-9239-66e8-a055-00163e0109a7"
              this.listWarehouse.forEach((i) => {
                if(id1 == i.id) {
                  this.listWarehouseNew.push(i)
                }
              })
            }
            if(this.listPerWh.includes('dashboard.type-13-2')) {
              let id2 = "1eda5f97-923c-6546-8556-00163e0109a7"
              this.listWarehouse.forEach((i) => {
                if(id2 == i.id) {
                  this.listWarehouseNew.push(i)
                }
              })
            }
            if(this.listPerWh.includes('dashboard.type-13-4')) {
              let id3 = "1eda5f97-9240-6f06-b38e-00163e0109a7"
              this.listWarehouse.forEach((i) => {
                if(id3 == i.id) {
                  this.listWarehouseNew.push(i)
                }
              })
            }
            if(this.listPerWh.includes('dashboard.type-13')) {
              this.listWarehouseNew = this.listWarehouse
            }
            if(this.listWarehouseNew.length > 0) {
              this.selectWH = this.listWarehouseNew[0].id;
              this.fetchDataWarehouseBranch(this.selectWH);
              this.fetchChart(this.selectWH);
            }
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // lấy ist kho
    fetchChart() {
      let filter = {};
      if (this.listWarehouseNew.length > 0) {
        filter.warehouse_id = this.selectWH;
        filter.user_type = "13";
      }
      axios.get("statistic/slow", { params: filter }).then((response) => {
        switch (response.code) {
          case 200:
            this.listChart = response.data;
            this.chartWasehouse.series[0].data = response.data.chart_user_type_13.chart_month;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    packetDetail(item) {
      let routeData = this.$router.resolve({name: 'pack-list', query: {value: item, time: this.filter.time, warehouse_id: this.selectWH}});
      window.open(routeData.href, '_blank');
    }
  },
  watch: {
    // chọn thời gian biểu đồ
    "filter.timeChart": function () {
      if(this.filter.timeChart == 'day') {
        this.chartWasehouse.xAxis.categories = this.dataDayChart;
        this.chartWasehouse.series[0].data = this.listChart.chart_user_type_13.chart_day
      } else {
        this.chartWasehouse.xAxis.categories = this.dataMonthchart;
        this.chartWasehouse.series[0].data = this.listChart.chart_user_type_13.chart_month
      }
    },
    //
    "selectWH": function () {
      this.fetchDataWarehouseBranch();
      this.fetchChart();
      this.filter.timeChart = "month"
      if(this.filter.timeChart == 'day') {
        this.chartWasehouse.xAxis.categories = this.dataDayChart;
        this.chartWasehouse.series[0].data = this.listChart.chart_user_type_13.chart_day
      } else {
        this.chartWasehouse.xAxis.categories = this.dataMonthchart;
        this.chartWasehouse.series[0].data = this.listChart.chart_user_type_13.chart_month
      }
    },
  },
};
</script>

<style lang="css" scoped>
.home-index.frame-content-common-2022 {
  background: #F7F8FF;
}
/* search top  */
.search-top-order {
  padding: 0 35px;
}
.search-top-order .width50 {
  width: 50%;
}
.search-top-order .width50 .title {
  width: 135px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000728;
  cursor: pointer;
}
.activeStatus {
  background: #E8F3FF;
  border-radius: 5px;
  border-bottom: 2px solid #45B4CE;
}
.title-rp-order {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #207CDC;
}
/* phần báo cáo  */
.order-report .content-report-frame-2023 {
  display: flex;
  align-items: center;
}
.order-report .numberReport {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.order-report .content-box-report {
  width: 25%;
  height: 110px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px 6px 8px 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.order-report .reportInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000728;
  margin-top: 12px;
}
.order-report .detail-report {
  margin-top: 11px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #767676;
  cursor: pointer;
}
.order-report .detail-report:hover > span {
  color: #0688EE;
}
.order-report .detail-report img {
  margin-right: 10px;
}
.order-report .weight-report {
  padding: 5px 0 11px 0;
}
.order-report .weight-report span {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3F92D1;
  background: #F7F8FF;
  padding: 3px 6px;
}
</style>
<style>
/* Phần search  */
.order-report .input-search-content {
  width: 80%;
}
.order-report .input-search-content input {
  background: #FFFFFF;
  border: 1px solid #3F92D1;
  border-radius: 8px;
  height: 36px;
}
.order-report .frame-content-search {
  justify-content: center;
  width: 20%;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
  background: #0688EE;
  border-radius: 8px;
  width: 120px;
  height: 36px;
  color: #fff;
}
/* biểu đồ  */
.order-report .chart-customer-column {
  /* width: 50%; */
  background: #fff;
  /* margin-right: 22px; */
  padding-top: 22px;
  margin: 10px;
}
.order-report .highcharts-container svg {
  font-family: 'Roboto', sans-serif !important;
}
.order-report .highcharts-credits {
  display: none;
}
.order-report .chart-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
/* chú thích biểu đồ  */
.height40 {
  height: 40px;
  padding: 10px 20px;
}
.height40 .icon {
  width: 12px;
  height: 12px;
  background: #F09920;
  border-radius: 50%;
}
.height40 .text {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000728;
  padding-left: 6px;
}
.chart-line-wasehouse {
  width: 100%;
  background: #fff;
  padding-top: 22px;
  margin: 10px;
}
.chart-line-wasehouse .title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
/*  */
.select-date input {
  width: 110px;
  height: 28px;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #767676;
}
.select-date .el-input__icon {
  line-height: 20px;
}
.order-report .select-date {
  width: auto;
}
.height40 .detail {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #767676;
}
/*  */
.percent50 {
  background: #fff;
  margin: 10px;
  width: 50%;
  padding: 20px 0 21px 32px;
  display: flex;
  align-items: center;
}
.percent50 .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: #FFEEE9;
  border-radius: 20px;
  margin-right: 24px;
}
.percent50 .info .number {
  font-weight: 700;
  font-size: 24px;
  line-height: 56px;
  letter-spacing: 0.02em;
}
.percent50 .info .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
}
/* màu number  */
.color1 {
  color: #207CDC;
}
.color2 {
  color: #F09920;
}
.color3 {
  color: #26B01C;
}
.color4 {
  color: #DF4747;
}
.color5 {
  color: #45B4CE;
}
.color6 {
  color: #ED18BE;
}
/*  */
.whSelect {
  margin-bottom: 20px;
}
.whSelect .el-input {
  width: 200px;
}
.whSelect input {
  width: 200px;
  height: 30px !important;
  font-size: 12px;
}
.whSelect .el-input__icon {
  line-height: 30px;
}
.select-padding {
  padding-left: 35px;
  width: 300px;
}
.report-info-whbranch {
  min-height: 250px;
}
.warehouse-branch:hover {
  cursor: pointer;
  color: #0b859f !important;
}
</style>
