<template>
  <div
    class="component-header-top d-flex flex-row justify-content-between align-items-center"
  >
    <div class="d-flex align-items-center">
      <div class="d-flex logo-top" @click="nexHome()">
        <img class="down" src="@/static/icon/header-top/logo.svg" />
      </div>
      <!-- // tìm kiếm tổng quát -->
      <div class="search-all-haed">
        <div class="box-search-all-pack d-flex flex-wrap input-seach-wrap-1">
          <div class="frame-item-filter-2022 filter-level">
            <el-select
              v-model="filter.module"
              placeholder="Lựa chọn module"
              class="frame-select-customer-2022"
              popper-class="frame-select-popper-customer-2022"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in listModule"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="frame-item-filter-2022 filter-phone">
            <el-input
              placeholder="Họ và tên"
              v-model="filter.nameAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="frame-item-filter-2022 filter-phone">
            <el-input
              placeholder="Số điện thoại"
              v-model="filter.phone_numberAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <!-- // module theo khách hàng -->
          <div
            class="frame-item-filter-2022 filter-name"
            :class="filter.module == 'customer' ? '' : 'search-all-item-none'"
          >
            <el-input
              v-if="filter.module == 'customer'"
              placeholder="Mã khách hàng"
              v-model="filter.customer_codeAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <!-- // module theo đơn hàng  -->
          <div class="frame-item-filter-2022 filter-phone" :class="filter.module == 'order' ? '' : 'search-all-item-none'">
            <el-input
              v-if="filter.module == 'order'"
              placeholder="Mã Khách hàng"
              v-model="filter.customer_code_order"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>

          <div class="frame-item-filter-2022 filter-phone" :class="filter.module == 'order' ? '' : 'search-all-item-none'">
            <el-input
              v-if="filter.module == 'order'"
              placeholder="Mã đơn hàng"
              v-model="filter.order_codeAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="frame-item-filter-2022 filter-level" :class="filter.module == 'order' ? '' : 'search-all-item-none'">
            <el-select
              v-if="filter.module == 'order'"
              v-model="filter.statusOrderAll"
              placeholder="Chọn trạng thái"
              class="frame-select-customer-2022"
              popper-class="frame-select-popper-customer-2022"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in listStatusOrder"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- // module theo kiện hàng  -->
          <div class="frame-item-filter-2022 filter-phone" :class="filter.module == 'pack' ? '' : 'search-all-item-none'">
            <el-input
              v-if="filter.module == 'pack'"
              placeholder="Mã Khách hàng"
              v-model="filter.customer_code_pack"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="frame-item-filter-2022 filter-phone" :class="filter.module == 'pack' ? '' : 'search-all-item-none'">
            <el-input
              v-if="filter.module == 'pack'"
              placeholder="Mã vận đơn"
              v-model="filter.bill_codeAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="frame-item-filter-2022 filter-phone" :class="filter.module == 'pack' ? '' : 'search-all-item-none'">
            <el-input
              v-if="filter.module == 'pack'"
              placeholder="Mã đơn kiện hàng"
              v-model="filter.order_code_packAll"
              class="frame-input-common-2022"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="frame-item-filter-2022 filter-level" :class="filter.module == 'pack' ? '' : 'search-all-item-none'">
            <el-select
              v-if="filter.module == 'pack'"
              v-model="filter.request_key_pack"
              placeholder="Chọn yêu cầu thêm"
              class="frame-select-customer-2022"
              popper-class="frame-select-popper-customer-2022"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in listCommonPack.requests"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
            </el-option>
            </el-select>
          </div>
          <div
            class="frame-item-filter-2022 filter-level"
            :class="filter.module == 'pack' ? '' : 'search-all-item-none'"
          >
            <el-select
              v-if="filter.module == 'pack'"
              v-model="filter.statusPackAll"
              placeholder="Chọn trạng thái"
              class="frame-select-customer-2022"
              popper-class="frame-select-popper-customer-2022"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in listCommonPack.statuses"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- // tìm kiếm -->
          <div class="search-button-all" @click="searchModule(true)">
            <span> Tìm kiếm </span>
          </div>
        </div>
      </div>























    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="noti-global d-flex" @click="showNoti()">
        <img class="down" src="@/static/icon/header-top/noti.svg" />
        <div class="number-noti" v-if="accountInfo.total_noti">
          <span v-if="accountInfo.total_noti.all > 0" class="count">{{ accountInfo.total_noti.all }}</span>
        </div>
      </div>
      <!-- pop-up no ti -->
      <div class="content-noti">
        <el-dialog
          title="Tips"
          :visible.sync="dialogVisibleNoti"
        >
          <div class="header d-flex justify-content-between">
            <!-- <span>Thông báo</span> -->

            <span class="header-left pl-2" v-if="tabActive == 1">Khách hàng</span>
            <span class="header-left pl-2" v-if="tabActive == 2">Đơn hàng</span>
            <span class="header-left pl-2" v-if="tabActive == 3">Kiện hàng</span>
            <span class="header-left pl-2" v-if="tabActive == 4">Giao hàng</span>
            <span class="header-left pl-2" v-if="tabActive == 5">Khiếu nại</span>
            <span class="header-left pl-2" v-if="tabActive == 6">Nộp phạt</span>
            <span class="header-left pl-2" v-if="tabActive == 7">Giỏ hàng</span>
            <span class="header-left pl-2" v-if="tabActive == 8">Tài chính</span>
            <span class="header-left pl-2" v-if="tabActive == 9">Ưu đãi</span>
            <span class="header-left pl-2" v-if="tabActive == 10">Hoàn tiền</span>
            <span class="header-left pl-2" v-if="tabActive == 11">Tải file</span>
            <span @click="dialogVisibleNoti = false" class="Cancel-noti">X</span>
          </div>
          <div class="body">
            <div class="title-top" @click="clickAllNoti()" style="padding: 15px 0px 15px 20px;">
              <span>Đánh dấu tất cả đã đọc</span>
            </div>
            <div class="content-body" style="overflow:auto;">
              <!-- <div class="d-flex justify-content-center pt-5" v-if="data.length == 0">
                <span>Không có dữ liệu</span>
              </div>
              <div v-else v-loading="loading">
                <div v-for="item in data" :key="item.title">
                  <div class="box-content d-flex justify-content-between" @click="seenNoti(item)">
                    <div class="d-flex align-items-center">
                      <div class="pr-2">
                        <img v-if="item.group == 1" src="@/static/icon/noti/khachhang.svg" alt="" />
                        <img v-if="item.group == 2" src="@/static/icon/noti/order.svg" alt="" />
                        <img v-if="item.group == 3" src="@/static/icon/noti/kienhang.svg" alt="" />
                        <img v-if="item.group == 4" src="@/static/icon/noti/giaohang.svg" alt="" />
                        <img v-if="item.group == 5" src="@/static/icon/noti/khieunai.svg" alt="" />
                        <img v-if="item.group == 6" src="@/static/icon/noti/nopphat.svg" alt="" />
                        <img v-if="item.group == 7" src="@/static/icon/noti/giohang.svg" alt="" />
                        <div v-if="item.group == 10" class="refund">
                          <i class="el-icon-refresh"></i>
                        </div>
                      </div>
                      <div class="d-flex flex flex-column ml-3">
                        <span class="title-content">{{ item.group_txt }}</span>
                        <span>{{ item.content }}</span>
                        <div class="d-flex align-items-center">
                          <img class="pr-2" src="@/static/icon/fund-transaction/list/ic_round-history.svg" alt="">
                          <span class="time">{{ item.time_create }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="icon-seen" v-if="item.time_seen == 0"></div>
                  </div>
                </div>
                <infinite-loading @infinite="infiniteHandler"></infinite-loading>
              </div> -->

              <el-row :gutter="24" class="row_noti">
                <el-col :span="4">
                  <div class="d-flex flex-column" v-if="accountInfo.total_noti">
                    <!-- //1 -->
                    <div class="icon-left-noti" :class="tabActive == 1 ? 'icon-active' : ''" @click="changeTab(1)">
                      <el-badge :value="accountInfo.total_noti.customer" :max="99" class="item">
                        <img v-if="tabActive == 1" src="@/static/icon/noti_v2/khachhangac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/khachhang.svg"/>
                      </el-badge>
                    </div>
                    <!-- //2 -->
                    <div class="icon-left-noti" :class="tabActive == 2 ? 'icon-active' : ''" @click="changeTab(2)">
                      <el-badge :value="accountInfo.total_noti.order" :max="99" class="item">
                        <img v-if="tabActive == 2" src="@/static/icon/noti_v2/donhangac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/donhang.svg"/>
                      </el-badge>
                    </div>
                    <!-- //3 -->
                    <div class="icon-left-noti" :class="tabActive == 3 ? 'icon-active' : ''" @click="changeTab(3)">
                      <el-badge :value="accountInfo.total_noti.package" :max="99" class="item">
                        <img v-if="tabActive == 3" src="@/static/icon/noti_v2/kienhangac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/kienhang.svg"/>
                      </el-badge>
                    </div>
                    <!-- //4 -->
                    <div class="icon-left-noti" :class="tabActive == 4 ? 'icon-active' : ''" @click="changeTab(4)">
                      <el-badge :value="accountInfo.total_noti.delivery" :max="99" class="item">
                        <img v-if="tabActive == 4" src="@/static/icon/noti_v2/giaohangac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/giaohang.svg"/>
                      </el-badge>
                    </div>
                    <!-- //5 -->
                    <div class="icon-left-noti" :class="tabActive == 5 ? 'icon-active' : ''" @click="changeTab(5)">
                      <el-badge :value="accountInfo.total_noti.complain" :max="99" class="item">
                        <img v-if="tabActive == 5" src="@/static/icon/noti_v2/khieunaiac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/khieunai.svg"/>
                      </el-badge>
                    </div>
                    <!-- //6  -->
                    <div class="icon-left-noti" :class="tabActive == 6 ? 'icon-active' : ''" @click="changeTab(6)">
                      <el-badge :value="accountInfo.total_noti.fine" :max="99" class="item">
                        <img v-if="tabActive == 6" src="@/static/icon/noti_v2/nopphatac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/nopphat.svg"/>
                      </el-badge>
                    </div>
                    <!-- //7 -->
                    <div class="icon-left-noti" :class="tabActive == 7 ? 'icon-active' : ''" @click="changeTab(7)">
                      <el-badge :value="accountInfo.total_noti.cart" :max="99" class="item">
                        <img v-if="tabActive == 7" src="@/static/icon/noti_v2/giohangac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/giohang.svg"/>
                      </el-badge>
                    </div>
                    <!-- //8 -->
                    <div class="icon-left-noti" :class="tabActive == 8 ? 'icon-active' : ''" @click="changeTab(8)">
                      <el-badge :value="accountInfo.total_noti.finance" :max="99" class="item">
                        <img v-if="tabActive == 8" src="@/static/icon/noti_v2/taichinhac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/taichinh.svg"/>
                      </el-badge>
                    </div>
                    <!-- //9 -->
                    <div class="icon-left-noti" :class="tabActive == 9 ? 'icon-active' : ''" @click="changeTab(9)">
                      <el-badge :value="accountInfo.total_noti.endow" :max="99" class="item">
                        <img v-if="tabActive == 9" src="@/static/icon/noti_v2/uudaiac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/uudai.svg"/>
                      </el-badge>
                    </div>
                    <!-- //10 -->
                    <div class="icon-left-noti" :class="tabActive == 10 ? 'icon-active' : ''" @click="changeTab(10)">
                      <el-badge :value="accountInfo.total_noti.refund" :max="99" class="item">
                        <img v-if="tabActive == 10" src="@/static/icon/noti_v2/hoantienac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/hoantien.svg"/>
                      </el-badge>
                    </div>
                    <!-- //11 -->
                    <div class="icon-left-noti" :class="tabActive == 11 ? 'icon-active' : ''" @click="changeTab(11)">
                      <el-badge :value="accountInfo.total_noti.file" :max="99" class="item">
                        <img v-if="tabActive == 11" src="@/static/icon/noti_v2/taifileac.svg"/>
                        <img v-else src="@/static/icon/noti_v2/taifile.svg"/>
                      </el-badge>
                    </div>

                  </div>
                </el-col>

                <el-col :span="20">
                  <div v-if="tabActive" v-loading="loading">
                    <div v-if="dataNoti.length > 0">
                      <div v-for="item in dataNoti" :key="item.id.index">
                        <div class="content-right-noti" @click="seenNoti(item)"
                             :class="item.time_seen == 0 ? 'content-right-noti-active' : ''">
                          <span class="time-noti">{{ item.created_at }}</span>

                          <span v-if="item.group == 3" class="pl-1">
                          {{ item.content}}
                        </span>
                          <span v-if="item.group == 3" class="pl-1 status-pack">
                          {{ item.data.pack_status_txt }}
                        </span>

                          <span v-else class="pl-1">{{ item.content }}</span>
                        </div>
                      </div>
                      <infinite-loading @infinite="infiniteHandler"></infinite-loading>

                    </div>
                    <div v-else>
                      <div class="content-right-noti align-items-center noti-none">
                        <span>Không có thông báo</span>
                      </div>
                    </div>
                  </div>
                </el-col>

              </el-row>



            </div>
          </div>
        </el-dialog>
      </div>
      <!--  -->



      <div
        class="dropdown-account"
        ref="dropdownProfile"
        @click="showMenuTop = !showMenuTop"
      >
        <img
          v-if="accountInfo.avatar"
          :src="accountInfo.avatar"
          class="avatar"
        />
        <span v-else><img src="@/static/icon/header-top/account.svg" /></span>
        <div class="d-flex flex-column">
          <span class="name">
            {{ accountInfo.name }}
            <img class="down" src="@/static/icon/header-top/down.svg" />
          </span>
          <span class="name pt-1" style="font-size: 9px;">
            ({{ accountInfo.organization && accountInfo.organization.name ? accountInfo.organization.name : '' }})
          </span>
        </div>
        <ul v-if="showMenuTop">
          <router-link
            :to="{ name: 'profile-user', params: {} }"
            @click.native.stop="showMenuTop = false"
          >
            <span>Thông tin tài khoản</span>
          </router-link>
          <a class="divider"></a>
          <a @click.stop.stop="confirmLogout">
            <span>Thoát tài khoản</span>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LayoutHeaderTop",
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      tabActive: 1,
      dataNoti: [],
      seach: "",
      showMenuTop: false,
      dialogVisibleNoti: false,
      listModule: [],
      listModuleAll: [
        {
          name: "Đơn hàng",
          value: "order",
        },
        {
          name: "Kiện hàng",
          value: "pack"
        },
        {
          name: "Khách hàng",
          value: "customer"
        }
      ],
      listModuleOrder: [
        {
          name: "Kiện hàng",
          value: "pack"
        },
        {
          name: "Khách hàng",
          value: "customer"
        }
      ],
      listModulePack: [
        {
          name: "Đơn hàng",
          value: "order",
        },
        {
          name: "Khách hàng",
          value: "customer"
        }
      ],
      listModuleCtm: [
        {
          name: "Đơn hàng",
          value: "order",
        },
        {
          name: "Kiện hàng",
          value: "pack"
        },
      ],
      //
      loading: false,
      data: [],
      filter: {
        page: 1,
        per_page: 10,
        // search theo màn
        module: "",
        nameAll: "",
        phone_numberAll: "",
        order_codeAll: "",
        statusOrderAll : "",
        customer_codeAll : "",
        bill_codeAll : "",
        order_code_packAll : "",
        statusPackAll : "",
        request_key_pack: "", // yêu cầu thêm
        customer_code_pack: "",
        customer_code_order: ""
      },
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        last_page: 0,
      },
      listStatusOrder: [],
      listCommonPack: [],

    };
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  mounted() {
    this.socketNoti();
    this.checkPath();
    this.fetchStatusOrder();
    this.fetchCommonPack();
  },
  computed: {
    ...mapGetters("account", ["accountInfo", "accountNoti"]),
    ...mapGetters(["path", "full_path"]),
    //
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),


    checkPath() {
      if(this.full_path == '/order-list') {
        this.listModule = this.listModuleOrder;
        this.filter.module = "";
      } else if(this.full_path == '/pack/list') {
        this.listModule = this.listModulePack;
        this.filter.module = "";
      }
      else if(this.full_path == '/customer/customer-lists') {
        this.listModule = this.listModuleCtm;
        this.filter.module = "";
      }
      else {
        this.listModule = this.listModuleAll
      }
    },
    searchModule() {
      this.filter.page = 1;
      if (this.filter.module == "") {
        this.$notify({
          title: "Vui lòng kiểm tra lại thông tin",
          message: "Vui lòng chọn module cần tìm kiếm",
          type: "warning",
        });
        return;
      }
      if(this.filter.module == 'order') {
        return this.$router.push({
          name: "order-list",
          params: {
            name: this.filter.nameAll,
            customer_phone: this.filter.phone_numberAll,
            order_code: this.filter.order_codeAll,
            status: this.filter.statusOrderAll,
            searchModule: true,
            customer_code: this.filter.customer_code_order
          },
        });
      }
      if (this.filter.module == "pack") {
        return this.$router.push({
          name: "pack-list",
          params: {
            name: this.filter.nameAll,
            phone_number: this.filter.phone_numberAll,
            customer_code: this.filter.customer_code_pack,
            bill_code: this.filter.bill_codeAll,
            order_code: this.filter.order_code_packAll,
            request_key: this.filter.request_key,
            status: this.filter.statusPackAll,
            searchModule: true,
          },
        });
      }
      if(this.filter.module == 'customer') {
        return this.$router.push({
          name: "customer-lists",
          params: {
            name: this.filter.nameAll,
            phone_number: this.filter.phone_numberAll,
            customer_code: this.filter.customer_codeAll,
            searchModule: true,
          },
        });
      }
    },
    fetchCommonPack() {
      if (this.filter.module !== 'pack') return;
      axios.get("common/package").then((response) => {
        switch (response.code) {
          case 200:
            this.listCommonPack = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    fetchStatusOrder() {
      if (this.filter.module !== 'order') return;
      axios.get("common/order").then((response) => {
        switch (response.code) {
          case 200:
            this.listStatusOrder = response.data.statuses
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    socketNoti() {
      // Join vào room
      this.$socket.emit("join-to-room-code", this.accountInfo.code);
    },
    // content-noti
    showNoti() {
      this.dialogVisibleNoti = true;
      this.filter.page = 1;
      this.getAccountInfo();
      this.fetchDataNoti();
    },
    infiniteHandler($state) {
      this.fetchDataNoti(true, $state);
    },

    // đổi tab noti
    changeTab(tab) {
      this.tabActive = tab;
      this.tabLeft = tab;
      this.filter.page = 1;
      this.infiniteId += 1;
      this.fetchDataNoti();
    },

    fetchDataNoti(loading = true, $state = '') {
      if (loading) {
        this.loading = true;
      }
      let filter = {
        // page: this.filter.page,
        // per_page: this.filter.per_page,
        id_sort: 'desc',
        group: this.tabActive,
        page: this.filter.page,
        per_page: this.filter.per_page,
      }
      axios.get("notification", {params: filter}).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
          // this.dataToShow = response.data.items;
          var dataItems      = response.data.items;
          var dataPagination = response.data.pagination;

          if ($state) {
              if (this.filter.page > 1) {
                this.dataNoti.push(...dataItems);
              }

              this.filter.page += 1;
            } else {
              this.dataNoti = dataItems;
            }

            this.pagination.total    = dataPagination.total;
            this.pagination.per_page = dataPagination.per_page;

            if ($state) {
              if (this.filter.page > dataPagination.last_page) {
                $state.complete();
              } else {
                $state.loaded();
              }
            }
            break
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // thao tác
    // xem all noti
    clickAllNoti() {
      this.dataNoti.forEach((i) => {
        i.time_seen = 1;
      });
      axios.post("notification/click-all-noti").then((response) => {
        switch (response.code) {
          case 200:
            this.accountInfo.total_noti.all = 0;
            this.getAccountInfo();
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // xem từng noti
    seenNoti(item) {
      if(item.time_seen == 0) {
        axios.post("notification/click-noti/" + item.id).then((response) => {
          switch (response.code) {
            case 200:
              if(this.accountInfo.total_noti > 0) {
                this.accountInfo.total_noti = this.accountInfo.total_noti - 1;
              }
              this.dialogVisibleNoti = false;
              this.fetchDataNoti();
              this.showDetail(item)
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      } else {
        this.showDetail(item)
      }
    },
    // chuyển màn
    showDetail(item) {
      if(item.name == 1001 || item.name == 1002) {
        window.location.assign(location.href = "/customer/customer-lists/detail/" + item.data.customer_id)
      }
      if(item.name == 2001 || item.name == 2002 || item.name == 2003 || item.name == 2004 || item.name == 2005 || item.name == 2006 || item.name == 2011 || item.name == 2012 || item.name == 2014) {
        window.location.assign(location.href = "/order-list/detail/" + item.data.order_id)
      }
      if(item.name == 2013) {
        this.$router.push({ name: "accountant-advance-money" });
      }
      if(item.name == 3001 || item.name == 2007 || item.name == 3002) {
        window.location.assign(location.href = "/pack/detail/" + item.data.pack_id)
      }
      if(item.name == 2008 || item.name == 6001 || item.name == 6002 || item.name == 6003) {
        // window.location.assign("http://localhost:8082/fines/fines-lists")
        this.$router.push({
          name: "fines-lists",
        });
      }
      if(item.name == 4001 || item.name == 4002 || item.name == 4003 || item.name == 4004 ) {
        window.location.assign(location.href = "/delivery-detail/" + item.data.delivery_id)
      }
      if(item.name == 5001 || item.name == 5002 || item.name == 5003) {
        window.location.assign(location.href = "/complain-list/detail/" + item.data.complain_id)
      }
      if(item.name == 10001) {
        window.location.assign(location.href = "/refund")
      }
      if(item.name == 11001 || item.name == 11003 || item.name == 11005){
        this.downLoadFile(item.id)
      }
      if(item.name == 3003) {
        window.location.assign(location.href = "/pack/refuns")
      }
      if(item.name == 3004) {
        window.location.assign(location.href = "/pack/wait-confirm")
      }
      if(item.name == 5007) {
        window.location.assign(location.href = "/customer/change-staff-requests")
      }
      this.dialogVisibleNoti = false
    },
    downLoadFile(notificationId){
      axios.get(`notification/download-file/${notificationId}`, { responseType: 'blob' })
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "hangve.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    //
    ...mapActions("account", ["removeAccountInfo"]),
    mouseLeave() {
      this.showMenuTop = false;
    },
    documentClick(e) {
      let el = this.$refs.dropdownProfile;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.showMenuTop = false;
      }
    },
    confirmLogout() {
      this.$confirm("Xác nhận thoát tài khoản", "Warning", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
        type: "warning",
      })
        .then(() => {
          this.removeAccountInfo();
        })
        .catch(() => {});
    },
    nexHome() {
      this.$router.push({
        name: "home-index",
      });
    }
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  watch: {
    full_path: function() {
      setTimeout(() => {
        this.checkPath();
      }, 200);
    }
  },
  sockets: {
    connect() {
      setTimeout(() => {
        this.socketNoti();
      }, 1500);
    },
    "global-info": function (data) {
      // console.log(data);
      if (data.channel == 'noti-admin') {
        this.accountInfo.total_noti.all += 1;
      }
    },
  }
};
</script>

<style lang="css" scoped>
.refund {
  padding: 2px 7px;
  background: rgb(240, 153, 32);
  border-radius: 5px;
  color: #fff;
}
.component-header-top {
  height: 40px;
  padding: 0px 15px;
}

.dropdown-account {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0px 3px 0px 10px;
  position: relative;
}
.dropdown-account img {
  margin-right: 8px;
  margin-top: 4px;
}
.dropdown-account .down {
  width: 10px;
  margin-right: 0px;
  margin-left: 5px;
}
.dropdown-account .name {
  color: #fff;
  font-size: 12px;
}
.dropdown-account ul {
  position: absolute;
  top: 50px;
  right: 0px;
  margin: 0px;
  width: 200px;
  background-color: #fff;
  padding: 5px 0px 5px 0px;
  z-index: 1000;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.dropdown-account ul a {
  list-style-type: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-decoration: none;
  color: #2c3e50;
}
.dropdown-account ul a:hover {
  background-color: #f2f2f2;
}
.dropdown-account ul a.divider {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 5px;
}

.noti-global {
  /* padding-right: 30px; */
  /* position: relative; */
}
.noti-global .count {
  /* position: absolute; */
  /* display: inline-block; */
  padding: 2px 3px;
  background-color: #cd4b4b;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  /* top: -5px;
  right: 12px; */
}
.number-noti {
  margin-top:-10px;
  margin-right: 5px;
}
.logo-top {
  padding-left: 30px;
  padding-left: 5px;
  cursor: pointer;
}
</style>
<style type="text/css">
.component-header-top input {
  border-radius: 25px;
  height: 28px;
  font-size: 12px;
  border: none;
}
.component-header-top .el-input__icon {
  line-height: 28px;
}
.avatar {
  max-width: 100%;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
/* content-noti */
.Cancel-noti {
  cursor: pointer;
}
.noti-global {
  cursor: pointer;
}
.content-noti .el-dialog  {
  position: absolute;
  right: 26px;
  top: -40px;
}
.content-noti .el-dialog {
  width: 500px;
}
.content-noti .el-dialog__header {
  display: none;
}
.content-noti .el-dialog__footer {
  display: none;
}
.content-noti .el-dialog__body {
  padding: 0;
}
.content-noti .header {
  background: #172259;
  padding: 20px 20px;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
}
.content-noti .body {
  /* padding: 15px 0px 15px 20px; */
  color: #172259;
}
.content-noti .title-top {
  font-weight: 500;
  font-size: 14px;
  color: #172259;
  text-decoration: underline;
  background: #F0F1F6;
}
.content-noti .title-top span {
  cursor: pointer;
}
.content-noti .header::before {
  content: "";
    position: absolute;
    top: 10px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #172259;
    right: 104px;
    top: -14px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
.content-body {
  height: 500px;
  /* overflow-y: auto; */
  /* margin-top: 10px; */
}
.content-noti .box-content {
  box-shadow: 0 1px 4px rgba(0,0,0,.25);
  margin: 10px 20px 10px 0;
  padding: 10px 16px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 25px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  word-break: break-word;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #bdd6ee;
}

::-webkit-scrollbar-thumb {
  border-radius: 22px;
  /* background: linear-gradient(left, #fff, #e4e4e4); */
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

/* ::-webkit-scrollbar-thumb:active {
  background: linear-gradient(left, #22add4, #1e98ba);
} */

::-webkit-scrollbar {
  width: 5px;
}
.content-body .title-content {
  /* font-style: italic; */
  font-size: 15px;
}
.content-body .circular {
  width: 30px !important;
  height: 30px !important;
}
.content-body .time {
  font-size: 12px;
}
.icon-seen {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}
.infinite-status-prompt {
  display: none;
}
/*  */
.search-all-haed {
  /* background: #F6F8FF;
  padding: 16px 0 16px 24px; */
  margin-left: 50px;
}
.search-all-haed .frame-select-customer-2022 input {
  background: none;
  color: #fff;
  width: 130px;
}
.search-all-haed .frame-select-customer-2022 input::placeholder {
  color: #fff;
}
.search-all-haed .frame-select-customer-2022 .el-input .el-select__caret {
  color: #fff !important;
}
.search-all-haed .frame-input-common-2022 input {
  background: none;
  color: #fff;
  width: 120px;
}
.search-all-haed .frame-input-common-2022 input::placeholder {
  color: #fff;
}
.search-all-haed .frame-input-common-2022 .el-input .el-select__caret {
  color: #fff !important;
}
.txt-search-all {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #ED18BE;
}
.component-header-top .search-button-all {
  font-size: 12px;
  width: 110px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #0d1f76;
  color: #fff;
  cursor: pointer;
}
.search-all-item-none {
  display: none;
}

/* // noti mới  */
.icon-left-noti {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #F0F1F6;
  border-bottom: 1px solid #B4B4B4;
  cursor: pointer;
  /* position: relative; */
}

.icon-left-noti img {
  width: 28px;
  height: 28px;
}
.row_noti {
  margin: 0 !important;
  /* margin-left: 0px !important; */
}
.row_noti .el-col-4 {
  padding: 0 !important;
  width: 70px;
}
.row_noti .el-col-20 {
  padding: 0 !important;
}
.icon-active {
  background: #172259;
}
.content-right-noti {
  color: #172259;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.28px;
  border-bottom: 1px solid #B4B4B4;
  word-break: break-word;
  padding: 10px 8px 10px 16px;
  cursor: pointer;
}

.content-right-noti:hover {
  color: #F09920;
}

.content-right-noti:hover > .status-pack {
  color: #F09920;
}

.content-right-noti-active {
  background: #C9E1E9;
}

.content-right-noti-active:hover {
  color: #F09920;
}
</style>
