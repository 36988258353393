<template>
  <div class="home-index frame-content-common-2022 order-report maketingrp" id="ST2022">
    <div class="search-top-order d-flex align-items-center">
      <div class="width50">
        <span class="title-rp-order">Thống kê nhanh</span>
        <div class="pt-2">
          <div class="d-flex align-items-center">
            <div
              v-for="i in listStatus"
              :key="i.value"
              @click="changeStatus(i)"
            >
              <div class="title" :class="[i.value === filter.time ? 'activeStatus' : '']">
                <span>{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Báo cáo -->
    <div v-loading="loading" class="report-infoMKT">
      <div class="content-report-frame-2023 mt-3" v-if="listData.arr_customer">
        <div class="content-box-report">
          <span class="numberReport color3 maketing" @click="customerDetail()">{{ listData.arr_customer.total_new | vnd2 }}</span>
          <span class="reportInfo">Tổng khách hàng mới</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color4 maketing" @click="customerDetail('total_place_order_mkt')">{{ listData.arr_customer_order.total_place_order | vnd2 }}</span>
          <span class="reportInfo">Tổng khách hàng lên đơn</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color2 maketing" @click="customerDetail('total_place_ordered_mkt')">{{ listData.arr_customer_order.total_place_ordered | vnd2 }}</span>
          <span class="reportInfo">Tổng khách hàng đặt hàng</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color6 maketing" @click="customerDetail('total_consignment')">{{ listData.arr_customer.total_consignment | vnd2 }}</span>
          <span class="reportInfo">Tổng khách hàng ký gửi</span>
        </div>
        <div class="content-box-report">
          <span class="numberReport color5 maketing" @click="customerDetail('total_order')">{{ listData.arr_customer.total_order | vnd2 }}</span>
          <span class="reportInfo">Tổng khách hàng order</span>
        </div>
      </div>
    </div>
    <!-- Biểu đồ -->
    <div class="d-flex justify-content-center">
      <div class="chart-customer-column">
        <span class="chart-title">Biểu đồ khách hàng mới</span>
        <div class="height40">
          <el-select
            v-model="filter.timeClientNew"
            placeholder="Theo tháng"
            class="select-date frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
          >
            <el-option
              v-for="item in timerChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <highcharts class="chart" :options="chartClientNew"></highcharts>
      </div>
      <div class="chart-customer-column">
        <span class="chart-title">Biểu đồ đặt hàng</span>
        <div class="height40">
          <el-select
            v-model="filter.timeOrder"
            placeholder="Theo tháng"
            class="select-date frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
          >
            <el-option
              v-for="item in timerChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <highcharts class="chart" :options="chartOrder"></highcharts>
      </div>
    </div>
    <!--  -->
    <div class="d-flex justify-content-center">
      <div class="chart-customer-column">
        <span class="chart-title">Biểu đồ khách hàng đặt hàng theo kênh</span>
        <div class="height40">
          <el-select
            v-model="filter.timeOrderWeb"
            placeholder="Theo tháng"
            class="select-date frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
          >
            <el-option
              v-for="item in timerChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <highcharts class="chart" :options="chartWebsite"></highcharts>
      </div>
      <div class="chart-customer-column">
        <span class="chart-title">Biểu đồ khách hàng đặt hàng theo chi nhánh</span>
        <div class="height40">
          <div class="d-flex align-items-center">
            <el-select
              v-model="filter.timeOrderBranch"
              placeholder="Theo tháng"
              class="select-date frame-select-common-2022"
              popper-class="frame-select-popper-common-2022"
            >
              <el-option
                v-for="item in timerChart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select
              v-model="filter.wareIdBranch"
              placeholder="Chi nhánh"
              class="select-date frame-select-common-2022 ml-3 wareBranch"
              popper-class="frame-select-popper-common-2022"
            >
              <el-option
                v-for="item in listBranch"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
        <highcharts class="chart" :options="chartOrderLine"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Chart} from 'highcharts-vue'
export default {
  name: "MaketingIndex",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng Trung Quốc",
    };
  },
  components: {
    highcharts: Chart
  },
  data() {
    return {
      input: "",
      loading: true,
      timerChart: [
        {
          label: 'Theo ngày',
          value: 'day',
        },
        {
          label: 'Theo tháng',
          value: 'month',
        },
      ],
      filter: {
        time: "d",
        timeClientNew: "month",
        timeOrder: "month",
        timeOrderWeb: "month",
        timeOrderBranch: "month",
        //
        wareIdBranch: "VIHNTX",
      },
      listStatus: [
        {
          name: "Trong ngày",
          value: "d",
        },
        {
          name: "Trong tuần",
          value: "w",
        },
        {
          name: "Tháng",
          value: "m",
        },
        {
          name: "Quý",
          value: "q",
        },
        {
          name: "Năm",
          value: "y",
        },
      ],
      // biểu đồ khách hàng mới
      chartClientNew: {
        chart: {
          type: 'line',
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        legend: {
          enabled: false,
        },
        series:[
            {
              name: '',
              data: [],
              color: "#F09920"
            },
        ],
      },
      // Biểu đồ đặt hàng
      chartOrder: {
        chart: {
          type: 'line',
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        legend: {
          enabled: false,
        },
        series:[
            {
              name: '',
              data: [],
              color: "#F09920"
            },
        ],
      },
      // biểu đồ đặt hàng theo kênh
      chartWebsite: {
        chart: {
          type: 'line',
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        // legend: {
        //   enabled: false,
        // },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0,
        },
        series:[],
      },
      // biểu đồ đặt hàng theo chi nhánh
      chartOrderLine: {
        chart: {
          type: 'line',
        },
        title: {
          text: '',
          align: 'left'
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
          gridLineWidth: 1,
          gridLineColor: "#BDD6EE",
        },
        // legend: {
        //   enabled: false,
        // },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0,
        },
        series:[
            {
              name: '',
              data: [],
            },
        ],
      },
      // data
      listData: {},
      listChart: {},
      listBranch: [],
      dataMonthchart: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
      dataDayChart: ['1','2','3','4','5','6','7','8','9','10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    };
  },
  mounted() {
    this.scrollTopST();
    this.fetchDataMKT();
    this.fetchChart();
  },
  methods: {
    scrollTopST() {
      setTimeout(() => {
        document.getElementById("ST2022").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    },
    // lọc
    changeStatus(item) {
      this.filter.time = item.value;
      this.fetchDataMKT()
    },
    // lấy dữ liệu
    fetchDataMKT() {
      this.loading = true;
      let filter = {
        time: this.filter.time,
        user_type: "10",
      }
      axios.get("statistic/fast", { params: filter }).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.listData = response.data;
            break;
          case 422:
            this.$notify.error({
              title: "Lỗi",
              message: response.data.error,
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // lấy dữ liệu biểu đồ
    fetchChart() {
      let filter  = {
        user_type: "10",
      }
      axios.get("statistic/slow", { params: filter }).then((response) => {
        switch (response.code) {
          case 200:
            this.listChart = response.data;
            this.chartClientNew.series[0].data = response.data.chart_user_type_10.customer_new.chart_month;
            this.chartOrder.series[0].data = response.data.chart_user_type_10.customer_place_ordered.chart_month;
            this.chartWebsite.series = response.data.chart_user_type_10.customer_ecommerce.chart_month;
            this.listBranch = response.data.chart_user_type_10.customer_warehouse.arr_warehouses;
            if(this.filter.wareIdBranch == 'VIHNTX') {
              this.chartOrderLine.series[0].data = response.data.chart_user_type_10.customer_warehouse.chart_month.VIHNTX;
              this.listBranch.forEach((item) => {
                if(this.filter.wareIdBranch == item.code) {
                  this.chartOrderLine.series[0].name = item.code_full
                }
              });
            }
            //
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    customerDetail(item) {
      let routeData = this.$router.resolve({name: 'customer-lists', query: {value: item, time:this.filter.time}});
      window.open(routeData.href, '_blank');
    },
    orderDetail(item){
      let routeData = this.$router.resolve({name: 'order-list', query: {value: item, time: this.filter.time}});
      window.open(routeData.href, '_blank');
    }
  },
  watch: {
    // chọn thời gian biểu đồ khách hàng mới
    "filter.timeClientNew": function () {
      if(this.filter.timeClientNew == 'day') {
        this.chartClientNew.xAxis.categories = this.dataDayChart;
        this.chartClientNew.series[0].data = this.listChart.chart_user_type_10.customer_new.chart_day
      } else {
        this.chartClientNew.xAxis.categories = this.dataMonthchart;
        this.chartClientNew.series[0].data = this.listChart.chart_user_type_10.customer_new.chart_month
      }
    },
    // chọn thời gian thống kê biểu đồ đặt hàng
    "filter.timeOrder": function () {
      if(this.filter.timeOrder == 'day') {
        this.chartOrder.xAxis.categories = this.dataDayChart;
        this.chartOrder.series[0].data = this.listChart.chart_user_type_10.customer_place_ordered.chart_day
      } else {
        this.chartOrder.xAxis.categories = this.dataMonthchart;
        this.chartOrder.series[0].data = this.listChart.chart_user_type_10.customer_place_ordered.chart_month
      }
    },
    // chọn tg biểu đồ đặt hàng theo kênh
    "filter.timeOrderWeb": function () {
      if(this.filter.timeOrderWeb == 'day') {
        this.chartWebsite.xAxis.categories = this.dataDayChart;
        this.chartWebsite.series = this.listChart.chart_user_type_10.customer_ecommerce.chart_day
      } else {
        this.chartWebsite.xAxis.categories = this.dataMonthchart;
        this.chartWebsite.series = this.listChart.chart_user_type_10.customer_ecommerce.chart_month
      }
    },
    // chọn tg biểu đồ đặt hàng theo chi nhánh
    "filter.timeOrderBranch": function() {
      if(this.filter.timeOrderBranch == 'day') {
        this.chartOrderLine.xAxis.categories = this.dataDayChart;
        this.chartOrderLine.series[0].data = this.listChart.chart_user_type_10.customer_warehouse.chart_day[this.filter.wareIdBranch];
      } else {
        this.chartOrderLine.xAxis.categories = this.dataMonthchart;
        this.chartOrderLine.series[0].data = this.listChart.chart_user_type_10.customer_warehouse.chart_month[this.filter.wareIdBranch];
      }
    },
    "filter.wareIdBranch": function() {
      if (this.filter.timeOrderBranch == 'day') {
        this.chartOrderLine.xAxis.categories = this.dataDayChart;
        this.chartOrderLine.series[0].data = this.listChart.chart_user_type_10.customer_warehouse.chart_day[this.filter.wareIdBranch];
        this.listBranch.forEach((item) => {
          if(this.filter.wareIdBranch == item.code) {
            this.chartOrderLine.series[0].name = item.code_full
          }
        });
      } else {
        this.chartOrderLine.xAxis.categories = this.dataMonthchart;
        this.chartOrderLine.series[0].data = this.listChart.chart_user_type_10.customer_warehouse.chart_month[this.filter.wareIdBranch];
        this.listBranch.forEach((item) => {
          if(this.filter.wareIdBranch == item.code) {
            this.chartOrderLine.series[0].name = item.code_full
          }
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.home-index.frame-content-common-2022 {
  background: #F7F8FF;
}
/* search top  */
.search-top-order {
  padding: 0 35px;
}
.search-top-order .width50 {
  width: 50%;
}
.search-top-order .width50 .title {
  width: 135px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000728;
  cursor: pointer;
}
.activeStatus {
  background: #E8F3FF;
  border-radius: 5px;
  border-bottom: 2px solid #45B4CE;
}
.title-rp-order {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #207CDC;
}
/* phần báo cáo  */
.order-report .content-report-frame-2023 {
  display: flex;
  align-items: center;
}
.order-report .numberReport {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.order-report .content-box-report {
  width: 20%;
  height: 110px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px 6px 8px 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.order-report .reportInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000728;
  margin-top: 12px;
}
.order-report .detail-report {
  margin-top: 11px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #767676;
  cursor: pointer;
}
.order-report .detail-report:hover > span {
  color: #0688EE;
}
.order-report .detail-report img {
  margin-right: 10px;
}
.order-report .weight-report {
  padding: 5px 0 11px 0;
}
.order-report .weight-report span {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3F92D1;
  background: #F7F8FF;
  padding: 3px 6px;
}
</style>
<style>
/* Phần search  */
.order-report .input-search-content {
  width: 80%;
}
.order-report .input-search-content input {
  background: #FFFFFF;
  border: 1px solid #3F92D1;
  border-radius: 8px;
  height: 36px;
}
.order-report .frame-content-search {
  justify-content: center;
  width: 20%;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
  background: #0688EE;
  border-radius: 8px;
  width: 120px;
  height: 36px;
  color: #fff;
}
/* biểu đồ */
.maketingrp .chart-customer-column {
  width: 50%;
  background: #fff;
  padding-top: 22px;
  margin: 10px;
}
.order-report .highcharts-container svg {
  font-family: 'Roboto', sans-serif !important;
}
.order-report .highcharts-credits {
  display: none;
}
.order-report .chart-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000728;
  padding-left: 22px;
}
.height40 {
  height: 40px;
  padding: 10px 20px;
}
/*  */
.order-report .select-date {
  width: auto;
}
.select-date input {
  width: 110px;
  height: 28px;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #767676;
}
.select-date .el-input__icon {
  line-height: 20px;
}
/* màu số  */
.color1 {
  color: #207CDC;
}
.color2 {
  color: #F09920;
}
.color3 {
  color: #26B01C;
}
.color4 {
  color: #DF4747;
}
.color5 {
  color: #45B4CE;
}
.color6 {
  color: #ED18BE;
}
/*  */
.report-infoMKT {
  min-height: 200px;
}
.wareBranch input {
  width: 150px !important;
}
.maketing:hover {
  cursor: pointer;
  color: #0688EE !important;
}
</style>
