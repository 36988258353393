import { render, staticRenderFns } from "./Index-order-manager.vue?vue&type=template&id=0e5a3857&scoped=true"
import script from "./Index-order-manager.vue?vue&type=script&lang=js"
export * from "./Index-order-manager.vue?vue&type=script&lang=js"
import style0 from "./Index-order-manager.vue?vue&type=style&index=0&id=0e5a3857&prod&lang=css&scoped=true"
import style1 from "./Index-order-manager.vue?vue&type=style&index=1&id=0e5a3857&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e5a3857",
  null
  
)

export default component.exports